import PlainModel from "@/apps/core/models/plainModel";
import { Diagnosis } from "@/apps/penyakit/models/diagnosis";
import { Komponen } from "@/apps/penyakit/models/komponen";

class PencapaianDiagnosis extends PlainModel {
  residenId: string | null = null;
  diagnosis: Diagnosis | null = null;
  target: number | null = null;
  pencapaian: number | null = null;
}

class PencapaianKeterampilan extends PlainModel {
  residenId: string | null = null;
  keterampilan: Komponen | null = null;
  target1: number | null = null;
  target2: number | null = null;
  target3: number | null = null;
  pencapaian1: number | null = null;
  pencapaian2: number | null = null;
  pencapaian3: number | null = null;
}

export { PencapaianDiagnosis, PencapaianKeterampilan };
