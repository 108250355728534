











































































import { defineComponent } from "@vue/composition-api";
import ResidenVM from "../models/residen";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "PencapaianKeterampilanDetail",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const residenVM = new ResidenVM();
    const { viewModelRef, instance } = useViewModel(paramsId, residenVM, {
      pencapaian: "keterampilan",
    });
    return {
      // Data
      viewModelRef,
      residen: instance,

      // Method
      toUserDateFormat,
    };
  },
});
