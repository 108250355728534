import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { DokterBase } from "./anggota";
import { PencapaianDiagnosis, PencapaianKeterampilan } from "./pencapaian";

export class Residen extends DokterBase {
  npm: string | null = null;
  angkatan: Record<string, any> | null = null;
  // subdivisi: Record<string, any> | null = null;
  extraData: Record<string, any> | null = null;
  pencapaianDiagnosisSet: Array<PencapaianDiagnosis> | null = null;
  pencapaianKeterampilanSet: Array<PencapaianKeterampilan> | null = null;

  loadData(json: Record<string, any>) {
    if (json.pencapaian_diagnosis_set) {
      const pencapaianDiagnosisSet: any = [];
      for (const pencapaian of json.pencapaian_diagnosis_set){
        const pencapaianDiagnosis = new PencapaianDiagnosis();
        pencapaianDiagnosis.loadData(pencapaian);
        pencapaianDiagnosisSet.push(pencapaianDiagnosis);
      }
      json.pencapaianDiagnosisSet = pencapaianDiagnosisSet;
    }


    if (json.pencapaian_keterampilan_set) {
      const pencapaianKeterampilanSet: any = [];
      for (const pencapaian of json.pencapaian_keterampilan_set){
        const pencapaianKeterampilan = new PencapaianKeterampilan();
        pencapaianKeterampilan.loadData(pencapaian);
        pencapaianKeterampilanSet.push(pencapaianKeterampilan);
      }
      json.pencapaianKeterampilanSet = pencapaianKeterampilanSet;
    }
    super.loadData(json);
  }
}

export class ResidenOptions extends PlainModel {
  nama: string | null = null;
}

export class ResidenAPI extends API {
  static modelPath = "/anggota/residen/";

  constructor() {
    super(ResidenAPI.modelPath);
  }
}

export default class ResidenVM extends ViewModel {
  constructor() {
    super(new ResidenAPI(), new Residen());
  }
}


export class ResidenOptionsAPI extends API {
  static modelPath = "/anggota/residen/options/";

  constructor() {
    super(ResidenOptionsAPI.modelPath);
  }
}
